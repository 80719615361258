// https://stackoverflow.com/a/74802803/23506530
const shareImage = async (blob, title, text) => {
  const filesArray = [
    new File([blob], `${title}.jpg`, {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    }),
  ];

  const shareData = {
    title: `${title}`,
    text,
    files: filesArray,
  };

  if (navigator.canShare && navigator.canShare(shareData)) {
    await navigator.share(shareData).then(function () {
      console.log('Successful share');
    })
      .catch(function (err) {
        console.error('Unsuccessful share ' + err);
      });
  }
  else {
    console.log("Cannot share");
  }
};

export default shareImage;