import { CAMPAIGN_BUTTON_TEXT, CAMPAIGN_LINK, FOOTER_LOGO_IMAGE, FOOTER_LOGO_LINK, FOOTER_LOGO_TITLE, SITE_TITLE } from "../constants";
import Button from "./Button";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <h2 className="footer__title">Make a pledge now</h2>
                    <div className="footer__button">
                        <Button
                            href={CAMPAIGN_LINK}
                            title={CAMPAIGN_BUTTON_TEXT}
                            target="_blank"
                            rel="noreferrer"
                            theme="red"
                        >
                            {CAMPAIGN_BUTTON_TEXT}
                        </Button>
                    </div>
                    <p className="footer__text">© {(new Date().getFullYear())} Guide Dogs SA/NT | ABN 91 183 168 093</p>
                    <div className="footer__logo">
                        <a href={FOOTER_LOGO_LINK} target="_blank" title={FOOTER_LOGO_TITLE} rel="noreferrer">
                            <img src={FOOTER_LOGO_IMAGE} alt={FOOTER_LOGO_TITLE} />
                        </a>
                    </div>
                </div>
            </footer >
        </>
    );
};

export default Footer;
