export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
export const SITE_DESCRIPTION = process.env.REACT_APP_SITE_DESCRIPTION;
export const SOCIAL_TITLE = process.env.REACT_APP_SOCIAL_TITLE;
export const SOCIAL_DESCRIPTION = process.env.REACT_APP_SOCIAL_DESCRIPTION;
export const SHARE_IMAGE = process.env.REACT_APP_SHARE_IMAGE;
export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const INPUT_TYPES = ["JPG", "JPEG", "PNG", "AVIF", "WEBP", "HEIC", "HEIF"];

export const OUTPUT_MIME_TYPE = "image/jpeg";
export const OUTPUT_FILE_TYPE = "jpg";
export const OUTPUT_QUALITY = 1;
export const CANVAS_PADDING_PERCENT = 0.05;

export const MESSAGES = [
  {
    id: 1,
    name: "Guide Dogs Day",
    description: "Help us dogs raise money for Guide Dogs",
    src: "/images/messages/help-us-dogs-raise-money-for–guide-dogs.png",
    fillRatio: 0.7,
    rotation: 5,
  },
  {
    id: 2,
    name: "Guide Dogs Day",
    description: "Dogs unite for Guide Dogs Day",
    src: "/images/messages/dogs-unite-for-guide-dogs-day.png",
    fillRatio: 0.7,
    rotation: -5,
  },
  {
    id: 3,
    name: "Guide Dogs Day",
    description: "Raising money to raise pups",
    src: "/images/messages/raising-money-to-raise-pups.png",
    fillRatio: 0.7,
    rotation: 5,
  },
  {
    id: 4,
    name: "Guide Dogs Day",
    description: "I've joined the pack for Guide Dogs Day",
    src: "/images/messages/ive-joined-the-pack-for-guide-dogs-day.png",
    fillRatio: 0.7,
    rotation: -5,
  },
  {
    id: 5,
    name: "Guide Dogs Day",
    description: "Anything helps. Dog bless",
    src: "/images/messages/anything-helps-dog-bless.png",
    fillRatio: 0.7,
    rotation: 5,
  },
  {
    id: 6,
    name: "Guide Dogs Day",
    description: "Give a dog a dollar",
    src: "/images/messages/give-a-dog-a-dollar.png",
    fillRatio: 0.7,
    rotation: -5,
  },
];

export const MESSAGE_ALIGNMENTS = [
  "high",
  "middle",
  "low"
]
export const DEFAULT_MESSAGE_ALIGNMENT = 'middle';

export const HASHTAGS = [
  "#GuideDogDay",
  "#DonateForDogs",
  "#GiveADogADollar",
]

export const CAMPAIGN_URL = 'www.guidedogsday.com.au';
export const CAMPAIGN_LINK = 'https://www.guidedogsday.com.au';
export const CAMPAIGN_BUTTON_TEXT = 'Pledge now';
export const CAPTION = "April 24 is Guide Dogs Day, and I'm raising money to raise a pup. Every dollar helps to breed, raise and train more Guide Dogs. Your dog can help too. Use the link in my bio to get your dog's own fundraising pawtrait, or pledge to donate.";
export const QR_CODE = '/images/qr-code.png';
export const CUSTOM_SIGN_LINK = 'https://www.youtube.com';

export const LOGO_IMAGE = '/images/logos/logo.png';
export const FOOTER_LOGO_TITLE = SITE_TITLE;
export const FOOTER_LOGO_IMAGE = '/images/logos/gd-logo.png';
export const FOOTER_LOGO_LINK = 'https://www.guidedogsday.com.au/get-involved';