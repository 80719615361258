import React, { useEffect } from "react";
import Nav from "./components/Nav";
import Head from "./components/Head";
import Form from "./components/Form";
import { FormProvider } from "./contexts/form.context";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Button from "./components/Button";
import TabbedContent from "./components/TabbedContent";
import initAnchors from "./utils/anchors";
import InRealLifeTab from "./components/tabbed-content/InRealLifeTab";
import OnlineTab from "./components/tabbed-content/OnlineTab";
import Tab from "./components/tabbed-content/Tab";
import TagManager from 'react-gtm-module'
import { GTM_ID } from "./constants";

const tagManagerArgs = {
    gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs)

function App() {
    initAnchors();
    useEffect(() => {
    }, []);

    return (
        <div className="App">
            <FormProvider>
                <Head />
                <Nav />

                {/* Hero */}
                <Hero
                    title="Lend a paw on"
                    subtitle="April 24"
                    image="/images/hero.jpeg"
                    mobileImage="/images/hero-mobile.jpeg"
                >
                    <p>Help us raise money to breed, raise, and train more Guide Dogs. Get your dog’s fundraising pawtrait, and check out our tips and tricks below for getting involved on the day.</p>
                    
                    <Button
                        href="#pawtrait"
                        title="Get your pawtrait"
                        rel="noopener"
                        theme="red"
                    >
                        Get your pawtrait
                    </Button>
                </Hero>

                <TabbedContent id="lend-a-paw" title="Dogs unite for Guide Dogs Day">
                    <Tab
                        className="tab--online"
                        title="Online"
                        image="/images/online.jpg"
                        number="1"
                        layout="text-image"
                    // preserveImage={false}
                    >
                        <OnlineTab number="1" />
                    </Tab>
                    <Tab
                        className="tab--in-real-life"
                        title="In Real Life"
                        number="2"
                        image="/images/in-real-life.jpg"
                        layout="image-text"
                        imagePosition="center bottom"
                    // preserveImage={true}
                    >
                        <InRealLifeTab number="2" />
                    </Tab>
                </TabbedContent>

                <Form id="pawtrait" />

                <Footer />
            </FormProvider>
        </div>
    );
}

export default App;
