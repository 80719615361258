import React from "react";
import MediaQuery from "react-responsive";

const MobileMediaQuery = ({ children }) => {
    return (
        <MediaQuery query="(max-width: 974px)">
            {children}
        </MediaQuery>
    );
};

export default MobileMediaQuery;
