import React from "react";
import StepHeading from "../StepHeading";
import { CUSTOM_SIGN_LINK, MESSAGES } from "../../constants";
import PawText from "../PawText";
import QRCode from "../QRCode";
import Button from "../Button";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

const InRealLifeTab = () => {
    const [lightboxOpen, setLightboxOpen] = React.useState(false);
    const [autoPlay, setAutoPlay] = React.useState(false);

    const slides = [
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/s_cxvW01Dis?autoplay=1&mute=1",
            width: 1280,
            height: 720,
        }
    ];

    return (
        <>
            <StepHeading number="1" title="Make your own cardboard sign" />
            <p>Grab some cardboard, string, a marker, and scissors, and choose one of our lines:</p>
            <ul className="no-list paw-texts">
                {MESSAGES.map((message, index) => (
                    <li key={index} className="">
                        <PawText>{message.description}</PawText>
                    </li>
                ))}
            </ul>
            <QRCode />
            <Button
                onClick={() => {
                    setLightboxOpen(true);
                    setAutoPlay(true);
                }}
                title="See how to make your sign"
                target="_blank"
                theme="red"
            >
                See how to make your sign
            </Button>
            <Lightbox
                plugins={[Video]}
                open={lightboxOpen}
                close={() => {
                    setLightboxOpen(false);
                    setAutoPlay(false);
                }}
                slides={slides}
                styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
                autoPlay={true}
                render={{
                    buttonPrev: slides.length <= 1 ? () => null : undefined,
                    buttonNext: slides.length <= 1 ? () => null : undefined,
                    
                    slide: ({ slide, rect }) =>
                        slide.type === "youtube" ? (
                        <iframe
                            width={Math.min(slide.width, rect.width, (slide.width * rect.height) / slide.height)}
                            height={Math.min(slide.height, rect.height, (slide.height * rect.width) / slide.width)}
                            src={slide.src}
                            title={slide.title}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    ) : null
                }}
                video={{
                    autoPlay,
                    muted: true,
                  }}
            />

            <StepHeading number="2" title="Grab your sign and your dog, and hit the streets" />
            <ul>
                <li>Get even more out of your walkies by stopping at every door</li>
                <li>Make April 24 "bring your dog to work" day at your workplace</li>
                <li>Meet up with your friends and move as a pack</li>
            </ul>
        </>
    );
};

export default InRealLifeTab;