import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { useFormContext } from "../../contexts/form.context";
import clsx from "clsx";
import { INPUT_TYPES } from "../../constants";

const Upload = ({ className }) => {
    const { image, setImage } = useFormContext();

    const handleChange = (file) => {
        reader.readAsDataURL(file);
    };

    var reader = new FileReader();
    reader.onload = function (event) {
        var img = new Image();
        img.onload = function () {
            setImage(img);
        }
        img.src = event.target.result;
    }

    return (
        <>
            <div className={clsx(className, "form-upload", { "form-upload--has-image": !!image })} >
                <FileUploader
                    classes="file-upload"
                    handleChange={handleChange}
                    name="file"
                    types={INPUT_TYPES}
                >
                    <img src="/images/dog-upload.png" alt="Upload" className="file-upload__icon" />

                    <p className="file-upload__title">Upload your dog photo</p>

                    <div className="button file-upload__button">
                        Browse files
                    </div>
                </FileUploader>
            </div>
        </>
    );
};

export default Upload;
