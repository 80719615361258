import React from "react";
import { useFormContext } from "../../contexts/form.context";
import clsx from "clsx";

const ChangeImage = ({ className }) => {
    const { image, valid, setImage } = useFormContext();

    const onClick = (e) => {
        e.preventDefault();
        setImage(null);
    };

    return !!image && valid && (
        <>
            <button className={clsx(className, "change-image")} onClick={onClick}>
                Change image
            </button>
        </>
    );
};

export default ChangeImage;
