import React from "react";
import { CAMPAIGN_BUTTON_TEXT, CAMPAIGN_LINK, LOGO_IMAGE, SITE_TITLE, SITE_URL } from "../constants";
import Button from "./Button";

const Nav = () => {
    return (
        <>
            <nav>
                <div className="container">
                    <div className="nav__logo">
                        <h1 className="visually-hidden">
                            {SITE_TITLE}
                        </h1>
                        <a
                            href={SITE_URL}
                            target=""
                            rel="no-opener"
                            className="site-logo"
                            title={SITE_TITLE}
                        >
                            <img
                                src={LOGO_IMAGE}
                                className=""
                                alt={SITE_TITLE}
                            />
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Nav;
