import React from "react";
import clsx from "clsx";
import StepHeading from "../StepHeading";

const CardHeading = ({ className, number, title, children }) => {
    return (
        <>
            <div className={clsx(className, "form-card__heading")}>
                <StepHeading number={number} title={title} />

                <div className="form-card__heading__children">
                    {children}
                </div>
            </div>
        </>
    );
};

export default CardHeading;
