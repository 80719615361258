import React from "react";
import MediaQuery from "react-responsive";

const DesktopMediaQuery = ({ children }) => {
    return (
        <MediaQuery query="(min-width: 975px)">
            {children}
        </MediaQuery>
    );
};

export default DesktopMediaQuery;
