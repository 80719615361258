/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "../../contexts/form.context";
import drawImageFilled from "../../utils/draw-image-filled";
import clsx from "clsx";
import { CANVAS_PADDING_PERCENT } from "../../constants";
import drawImageRotated from "../../utils/draw-image-rotated";

// Where el is the DOM element you'd like to test for visibility
function isHidden(el) {
    return (el.offsetParent === null)
}

/**
 * Future investigation:
 * - Pinch & zoom: https://codepen.io/AbramPlus/full/mdymKom
 * - Button zoom: https://bencentra.com/canvas/canvas4.html
 */
const Canvas = ({ width, height }) => {
    // Context
    const { setCanvas, image, message, messageAlignment } = useFormContext();

    // Ref
    const canvasRef = useRef(null);

    // Sync global canvas
    useEffect(() => {
        if (!canvasRef.current) return;
        setCanvas(canvasRef.current);
    }, [canvasRef]);

    // Render function
    const draw = useCallback((context) => {
        // Set canvas dimensions
        context.canvas.width = width;
        context.canvas.height = height;

        // Clear canvas
        context.clearRect(0, 0, width, height);

        // Set background
        context.fillStyle = '#000000';

        // Draw image
        if (image) {
            drawImageFilled(context, image, 0, 0, width, height);
        }

        // Draw message
        if (message && !!message.img) {
            let rotation = message.rotation || 0;
            let scale = 1;
            if (message.img.width > message.img.height) {
                scale = (width / message.img.width) * message.fillRatio;
            }
            else if (message.img.width < message.img.height) {
                scale = (height / message.img.height) * message.fillRatio;
            }

            let x = width / 2 - message.img.width * scale / 2;
            let y = 0;
            switch (messageAlignment) {
                case "high":
                    // physical center of the canvas
                    y = (height - message.img.height * scale) / 2;
                    break;
                case "middle":
                    // 3/4 down the height of the canvas
                    y = height * 3 / 4 - message.img.height * scale;
                    break;
                case "low":
                    y = height - message.img.height * scale;
                    // Add padding from bottom
                    y -= height * CANVAS_PADDING_PERCENT;
                    break;
                default:
            }

            // Add padding
            if (messageAlignment !== 'middle') {
                y -= height * CANVAS_PADDING_PERCENT;
            }

            // Draw image
            drawImageRotated(context, message.img, x, y, message.img.width * scale, message.img.height * scale, rotation);
        }
    }, [width, height, image, message, messageAlignment]);

    // Trigger animation
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        let animationFrameId;

        const render = () => {
            draw(context);
            animationFrameId = window.requestAnimationFrame(render);
        }

        render();

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [draw]);

    return (
        <>
            <canvas ref={canvasRef} />
        </>
    );
};

const CanvasContainer = ({ className }) => {
    // Refs
    const containerRef = useRef(null);

    // Flag to toggle module
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setEnabled(!isHidden(containerRef.current));
    }, [containerRef]);

    // Dimensions
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    // Set dimensions
    const onResize = () => {
        if (!enabled) return;

        const container = containerRef.current;
        setWidth(container.clientWidth);
        setHeight(container.clientHeight);
    }

    // Update dimensions on window resize
    useEffect(() => {
        if (!enabled) return;

        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, [enabled]);

    return (
        <>
            <div className={clsx(className, "form-canvas")}>
                <div className="form-canvas__container" ref={containerRef}>
                    {enabled && <Canvas width={width} height={height} />}
                </div>
            </div>
        </>
    );
};

export default CanvasContainer;
