import React from "react";
import clsx from "clsx";

const ButtonGroup = ({ className, value, options, onChange }) => {
    return (
        <>
            <div className={clsx(className, "button-group")} >
                {options && options.map((option, key) => (
                    <button
                        key={key}
                        onClick={() => onChange(option.value)}
                        className={clsx("button-group__button", { "button-group__button--selected": value === option.value })}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </>
    );
};

export default ButtonGroup;
