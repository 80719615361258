import React from "react";
import { Helmet } from "react-helmet";
import { SHARE_IMAGE, SITE_DESCRIPTION, SITE_TITLE, SITE_URL, SOCIAL_DESCRIPTION, SOCIAL_TITLE } from "../constants";

const Head = () => {
    return (
        <>
            <Helmet>
                <title>{SITE_TITLE} | Guide Dogs SA/NT</title>

                <meta http-equiv="cleartype" content="on" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />

                <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black" />

                <link rel="manifest" href="/manifest.json" />

                <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>

                <meta name="description" content={SITE_DESCRIPTION} />

                <meta property="og:title" content={SOCIAL_TITLE} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={SITE_URL} />
                <meta property="og:image" content={SHARE_IMAGE} />
                <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content={SOCIAL_TITLE} />
                <meta property="og:description" content={SOCIAL_DESCRIPTION} />
                <meta prefix="og: http://ogp.me/ns#" property="fb:admins" content="651073063" />
                <meta property="fb:app_id" content="" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={SITE_TITLE} />
                <meta name="twitter:title" content={SOCIAL_TITLE} />
                <meta name="twitter:description" content={SOCIAL_DESCRIPTION} />
                <meta name="twitter:image" content={SHARE_IMAGE} />
                <meta name="twitter:url" content={SITE_URL} />

                <link rel="canonical" href={SITE_URL} />
            </Helmet >
        </>
    );
};

export default Head;
