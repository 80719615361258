import getNavHeight from "./get-nav-height";

export const scrollToElement = (element) => {
    try {
        const navHeight = getNavHeight();
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - navHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    } catch (e) {
        console.log("scrollToElement error", e);
    }
};

export default scrollToElement;