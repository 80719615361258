export const getNavHeight = () => {
    try {
        const navs = document.querySelectorAll("nav");
        let navHeight = 0;
        navs.forEach((nav) => (navHeight += nav.offsetHeight));

        const wpMenuBar = document.getElementById("wpadminbar");
        const wpMenuBarHeight = wpMenuBar ? wpMenuBar.offsetHeight : 0;
        return navHeight + wpMenuBarHeight;
    } catch (e) { }
    return 0;
};

export default getNavHeight;