'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { MESSAGES, MESSAGE_ALIGNMENTS, DEFAULT_MESSAGE_ALIGNMENT } from '../constants';

const FormContext = createContext();

export function useFormContext() {
    return useContext(FormContext);
}

export function FormProvider({ children }) {
    // Canvas reference
    const [canvas, setCanvas] = useState(null);

    // Whole form state
    const [valid, setValid] = useState(false);

    // Uploaded image
    const [image, setImage] = useState(undefined);

    // Message
    const [message, setMessage] = useState();
    const [messageAlignment, setMessageAlignment] = useState(DEFAULT_MESSAGE_ALIGNMENT);
    const [messageIndex, setMessageIndex] = useState(0);
    const [activeMessage, setActiveMessage] = useState(MESSAGES[0]);

    // Update valid state
    useEffect(() => {
        setValid(!!image);
    }, [image]);

    // Load message image
    useEffect(() => {
        if (!activeMessage) return;

        var img = new Image();
        img.onload = function () {
            setMessage({
                ...activeMessage,
                img
            });
        }
        img.src = activeMessage.src;
    }, [activeMessage, setMessage]);

    // Assign active message
    useEffect(() => {
        setActiveMessage(MESSAGES[messageIndex]);
    }, [messageIndex, setActiveMessage]);

    return (
        <FormContext.Provider
            value={{
                canvas, setCanvas,
                valid,
                image, setImage,
                message, setMessage,
                messageAlignment, setMessageAlignment,
                messageIndex, setMessageIndex,
                activeMessage, setActiveMessage,
            }}
        >
            {children}
        </FormContext.Provider>
    );
}
