function drawImageRotated(ctx, img, x, y, w, h, rotation = 0) {
    // apply defaults
    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw);
    cy = (ih - ch);

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // Get center of the image
    const imageCenterX = x + w / 2;
    const imageCenterY = y + h / 2;

    // save the unrotated context of the canvas so we can restore it later
    // the alternative is to untranslate & unrotate after drawing
    ctx.save();

    ctx.translate(imageCenterX, imageCenterY);

    // rotate the canvas to the specified degrees
    ctx.rotate(rotation * Math.PI / 180);

    // fill image in dest. rectangle
    // if the context is rotated, the image will be rotated also
    ctx.drawImage(img, cx, cy, cw, ch, x - imageCenterX, y - imageCenterY, w, h);

    // we’re done with the rotating so restore the unrotated context
    ctx.restore();
}

export default drawImageRotated;