/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useFormContext } from "../../contexts/form.context";
import clsx from "clsx";
import { MESSAGES, MESSAGE_ALIGNMENTS } from "../../constants";
import ButtonGroup from "./ButtonGroup";
import { ReactComponent as LeftArrowIcon } from "../../icons/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../icons/right-arrow.svg";

export const MessageDots = ({ theme = 'light' }) => {
    const { messageIndex, setMessageIndex } = useFormContext();

    useEffect(() => {
        MESSAGES.forEach((message, index) => {
            const image = new Image();
            // image.onload = () => {
            //     console.log(`${message.name} loaded`);
            // }
            image.src = message.src;
        });
    }, []);

    return (
        <ul className="form-message__dots">
            {MESSAGES.map((message, index) => (
                <li
                    key={index}
                    onClick={() => setMessageIndex(index)}
                    className={clsx("form-message__dot", `form-message__dot--${theme}`, { "form-message__dot--selected": messageIndex === index })}
                ></li>
            ))}
        </ul>
    );
}

export const MessagePreview = () => {
    const { activeMessage } = useFormContext();

    return (
        <div
            className="form-message__preview"
            style={{
                backgroundImage: `url(${activeMessage.src})`,
            }}
        ></div>
    );
}

export const MessageAlignment = ({ layout = 'portrait' }) => {
    const { messageAlignment, setMessageAlignment } = useFormContext();

    return (
        <div className={clsx("form-message__alignment", `form-message__alignment--${layout}`)}>
            <p><b>Message position</b></p>
            <ButtonGroup
                value={messageAlignment}
                options={MESSAGE_ALIGNMENTS.map((alignment) => ({
                    value: alignment,
                    label: alignment
                }))}
                onChange={(alignment) => setMessageAlignment(alignment)}
            />
        </div>
    );
}

export const MessageArrows = () => {
    const { messageIndex, setMessageIndex } = useFormContext();

    return (
        <div className="form-message__arrows">
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setMessageIndex((messageIndex - 1 + MESSAGES.length) % MESSAGES.length);
                }}
                title="Previous Message Button"
                className="form-message__arrow form-message__arrow--left"
            >
                <LeftArrowIcon />
            </a>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setMessageIndex((messageIndex + 1) % MESSAGES.length);
                }}
                title="Next Message Button"
                className="form-message__arrow form-message__arrow--right"
            >
                <RightArrowIcon />
            </a>
        </div>
    );
}

export const MessageMobile = ({ className }) => {
    return (
        <>
            <div className={clsx(className, "form-message form-message--mobile")} >
                <MessageDots theme="dark" />
                <MessageArrows />
                <MessageAlignment layout="landscape" />
            </div>
        </>
    );
};

const MessageDesktop = ({ className }) => {
    return (
        <>
            <div className={clsx(className, "form-message form-message--desktop")} >
                <div className="form-message__image">
                    <MessagePreview />
                    <MessageDots />
                    <MessageAlignment />
                </div>
                <MessageArrows />
            </div>
        </>
    );
};

export default MessageDesktop;
