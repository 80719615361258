import React from "react";
import StepHeading from "../StepHeading";
import Button from "../Button";
import DesktopMediaQuery from "../DesktopMediaQuery";

const OnlineTab = () => {
    return (
        <>
            <StepHeading number="1" title="Before April 24" />
            <p>Download your pawtrait and share it via text, email and on socials to rally more dogs to lend paws for our cause. And to get pledges for donations.</p>
            <StepHeading number="2" title="On April 24" />
            <p>Share your pawtrait via text, email and on socials to encourage donations. </p>

            <DesktopMediaQuery>
                <Button
                    href="#pawtrait"
                    title="Get your pawtrait"
                    rel="noopener"
                    theme="red"
                >
                    Get your pawtrait
                </Button>
            </DesktopMediaQuery>
        </>
    );
};

export default OnlineTab;