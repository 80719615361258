import clsx from "clsx";
import { ReactComponent as PawIcon } from "../icons/paw-icon.svg";

const PawText = ({
    className,
    children
}) => {
    return (
        <>
            <p className={clsx(className, "paw-text")}>
                <PawIcon />
                {children}
            </p>
        </>
    );
};

export default PawText;
