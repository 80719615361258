import React from "react";
import MobileMediaQuery from "./MobileMediaQuery";
import DesktopMediaQuery from "./DesktopMediaQuery";

const Hero = ({
    title,
    subtitle,
    image,
    mobileImage,
    children
}) => {
    return (
        <>
            <div className="hero">
                <div className="container">
                    <div className="hero__image">
                        <DesktopMediaQuery>
                            <div className="hero__image__background" style={{ backgroundImage: `url(${image})` }}></div>
                        </DesktopMediaQuery>
                        <MobileMediaQuery>
                            <div className="hero__image__background" style={{ backgroundImage: `url(${mobileImage})` }}></div>
                        </MobileMediaQuery>
                    </div>
                    <div className="hero__content">
                        <h2 className="hero__title">
                            {title}
                            <span className="hero__subtitle">{subtitle}</span>    
                        </h2>
                        <img src="/images/hero-seperator.png" alt="title seperator" className="hero__seperator" />
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
