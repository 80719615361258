/* eslint-disable react-hooks/exhaustive-deps */
import React, { Children, cloneElement, isValidElement, useCallback, useEffect, useState } from "react";
import Button from "./Button";
import clsx from "clsx";
import { scrollToHashTarget } from "../utils/anchors";

const TabbedContent = ({
    id,
    title,
    children
}) => {
    const [firstRender, setFirstRender] = useState(true);
    const [activeTab, setActiveTab] = useState(1);

    const onClick = useCallback((e, tabNumber) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveTab(tabNumber);
    }, [setActiveTab]);

    const scrollToTab = useCallback((tabNumber) => {
        scrollToHashTarget(`#tab-${tabNumber}`);
    }, [setActiveTab]);

    useEffect(() => {
        if (firstRender) return;
        scrollToTab(activeTab);
    }, [activeTab]);

    useEffect(() => {
        setFirstRender(false);
    }, []);

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { activeTab });
        }
        return child;
    });

    return (
        <>
            <div id={id} className="tabbed-content">
                <div className="container">
                    <h2 className="h1 tabbed-content__title">
                        {title}
                    </h2>

                    {children && (
                        <>
                            <div className="tabbed-content__nav-wrapper">
                                <ul className="tabbed-content__nav">
                                    <>
                                        {children.map((children, index) => {
                                            const { props: { title, number } } = children;
                                            return (
                                                <li key={index}>
                                                    <Button
                                                        className={clsx("tabbed-content__nav__link", { 'active': parseInt(activeTab) === parseInt(number) })}
                                                        href={`#tab-${number}`}
                                                        title={title}
                                                        rel="noopener"
                                                        theme="grey"
                                                        onClick={(e) => onClick(e, number)}
                                                    >
                                                        {title}
                                                    </Button>
                                                </li>
                                            )
                                        })}
                                    </>
                                </ul>
                            </div>

                            <div className="tabbed-content__tabs">
                                {childrenWithProps}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default TabbedContent;
