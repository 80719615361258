import clsx from "clsx";
import React from "react";

const Button = ({
    className,
    theme,
    title,
    href,
    target = '_self',
    children,
    ...rest
}) => {
    return (
        <>
            <a className={clsx(className, "button", `button--${theme}`)} href={href} target={target} title={title} {...rest}>
                {children}
            </a>
        </>
    );
};

export default Button;
