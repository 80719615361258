import clsx from "clsx";
import { ReactComponent as QRCodeIcon } from "../icons/qr-code-icon.svg";
import { QR_CODE } from "../constants";

const QRCode = ({
    className,
    children
}) => {
    return (
        <>
            <p className={clsx(className, "qr-code")}>
                <QRCodeIcon />
                <span>
                    <a href={QR_CODE} target="_blank" title="QR Code" rel="noreferrer">Print and stick our QR code</a> to your sign or have it handy on your phone to make it easier to encourage donations.
                </span>
            </p>
        </>
    );
};

export default QRCode;
