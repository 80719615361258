/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useFormContext } from "../../contexts/form.context";
import clsx from "clsx";
import shareImage from "../../utils/share-image";
import { CAMPAIGN_URL, CAPTION, HASHTAGS, OUTPUT_FILE_TYPE, OUTPUT_MIME_TYPE, OUTPUT_QUALITY } from "../../constants";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { ReactComponent as ShareIcon } from "../../icons/share.svg";
import { ReactComponent as CopyIcon } from "../../icons/copy-icon.svg";

const CopyableText = ({ text }) => {
    const [copied, setCopied] = useState(false)

    const onClick = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        setCopied(true);
    }

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, 1000);

            return () => {
                clearTimeout(timeout);
            }
        }

    }, [copied]);

    return (
        <a
            href="#"
            onClick={onClick}
            className="form-actions__hashtag"
        >
            {text}
            <CopyIcon />

            {copied && <span className="form-actions__hashtag__copied">Copied!</span>}
        </a>

    )
}

const Actions = ({ className }) => {
    const { canvas, message, valid } = useFormContext();

    const name = message?.name || 'guide-dogs';
    const description = message?.description || 'Guide Dogs';
    const canShare = navigator.canShare && navigator.canShare({ files: [new File([''], `${name}.${OUTPUT_FILE_TYPE}`, { type: OUTPUT_MIME_TYPE })] });

    const onShareClick = () => {
        if (!canShare || !canvas) return;
        canvas.toBlob((blob) => {
            shareImage(blob, name, description);
        }, OUTPUT_MIME_TYPE, OUTPUT_QUALITY)
    }

    const onDownloadClick = () => {
        if (!canvas) return;
        var link = document.createElement('a');
        link.download = `${name}.${OUTPUT_FILE_TYPE}`;
        link.href = canvas.toDataURL(OUTPUT_MIME_TYPE, OUTPUT_QUALITY)
        link.click();
    }

    return (
        <>
            <div className={clsx(className, "form-actions")} >
                <div className="form-actions__download">
                    <p className="form-actions__header">
                        Download
                    </p>
                    <button onClick={() => onDownloadClick()} disabled={!valid}>
                        <DownloadIcon />
                        Download Image
                    </button>
                </div>

                {HASHTAGS && HASHTAGS.length > 0 && (
                    <div className="form-actions__hashtags">
                        <p className="form-actions__header">
                            Grab them hashtags
                        </p>

                        <ul>
                            {HASHTAGS.map((hashtag, index) => (
                                <li key={index}>
                                    <CopyableText text={hashtag} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="form-actions__url">
                    <p className="form-actions__header">
                        Add this URL to your bio
                    </p>
                    <CopyableText text={CAMPAIGN_URL} />
                </div>
                <div className="form-actions__caption">
                    <p className="form-actions__header">
                        Use our caption or write your own
                    </p>
                    <CopyableText text={CAPTION} />
                </div>

                {canShare && (
                    <div className="form-actions__share">
                        <p className="form-actions__header">
                            Share
                        </p>
                        <button onClick={() => onShareClick()} disabled={!valid}>
                            <ShareIcon />
                            Share
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Actions;
