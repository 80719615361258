import scrollToElement from "./scroll-to-element";

export const getAnchorTarget = (anchor) => {
    const url = document.createElement("a");
    url.href = anchor.getAttribute("href") || "";
    const hash = url.hash;
    if (!hash) return;
    const target = document.querySelector(hash);
    return target;
}

export const scrollToHashTarget = (hash) => {
    const target = document.querySelector(hash);
    if (target) {
        scrollToElement(target);
    }
}

export const anchors = () => {
    // auto-scrolling
    if (window.location.hash) {
        window.history.scrollRestoration = "manual";
        const target = document.querySelector(window.location.hash);
        if (target) {
            scrollToElement(target);
        }
    }

    document.addEventListener('click', (e) => {
        if (e.target.matches('[href^="#"]')) {
            const target = getAnchorTarget(e.target);
            if (target) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                scrollToElement(target);
                // window.location.hash = e.target.hash;
            }
        }
    });
};

export default anchors;
