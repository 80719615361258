import React from "react";
import Upload from "./form/Upload";
import { useFormContext } from "../contexts/form.context";
import Canvas from "./form/Canvas";
import Message, { MessageMobile } from "./form/Message";
import Actions from "./form/Actions";
import CardHeading from "./form/CardHeading";
import clsx from "clsx";
import MediaQuery from "react-responsive";
import ChangeImage from "./form/ChangeImage";
import DesktopMediaQuery from "./DesktopMediaQuery";
import MobileMediaQuery from "./MobileMediaQuery";

const Form = ({ id }) => {
    const { image, valid } = useFormContext();

    return (
        <>
            <div id={id} className="form-wrapper container">

                {/* Heading */}
                <h2 className="h1 form-heading">Create and download your pawtrait.</h2>

                {/* Desktop Layout */}
                <DesktopMediaQuery>
                    <div className="form form--desktop">
                        <div className="form-card form-card--image form-card--coral">
                            <CardHeading number="1" title="Upload your image" >
                                <ChangeImage />
                            </CardHeading>

                            <div className="form-image">
                                <Upload />
                                {image && (
                                    <Canvas />
                                )}
                            </div>
                        </div>

                        <div className={clsx("form-card form-card--message form-card--teal", { "form-card--disabled": !valid })}>
                            <CardHeading number="2" title="Choose your message" />
                            <Message />
                        </div>

                        <div className={clsx("form-card form-card--actions form-card--teal", { "form-card--disabled": !valid })}>
                            <CardHeading number="3" title="Share" />
                            <Actions />
                        </div>
                    </div>
                </DesktopMediaQuery>

                {/*  Mobile Layout */}
                <MobileMediaQuery>
                    <div className="form form--mobile">

                        {/*  Show standalone upload field initially */}
                        {!image ? (
                            <div className="form-card form-card--image form-card--coral">
                                <div className="form-image-header">
                                    <CardHeading title="Upload your photo" />
                                </div>

                                <div className="form-image">
                                    <Upload className={clsx("form-image__upload", { "form-image__upload--has-image": !!image })} />
                                </div>
                            </div>
                        ) :
                            // Show message and actions after image is uploaded
                            (
                                <>
                                    <div className="form-card form-card--editor form-card--teal">
                                        <CardHeading title="Choose your message" >
                                            <ChangeImage />
                                        </CardHeading>

                                        <div className="form-editor">
                                            <div className="form-editor__image">
                                                <Canvas className="form-image__canvas" />
                                            </div>
                                            <MessageMobile />
                                        </div>
                                    </div>

                                    <div className="form-card form-card--actions form-card--coral">
                                        <Actions />
                                    </div>
                                </>
                            )}
                    </div>
                </MobileMediaQuery>
            </div>
        </>
    );
};

export default Form;
