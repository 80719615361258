import React from "react";
import clsx from "clsx";

const StepHeading = ({ className, number, title }) => {
    return (
        <>
            <h3 className={clsx(className, "step-heading")}>
                {number && <span className="step-heading__number">{number}</span>}
                <span className="step-heading__title">{title}</span>
            </h3>
        </>
    );
};

export default StepHeading;
