import clsx from "clsx";
import React from "react";
import MediaQuery from "react-responsive";
import DesktopMediaQuery from "../DesktopMediaQuery";
import MobileMediaQuery from "../MobileMediaQuery";

const Tab = ({ className, preserveImage = false, imagePosition = "center", layout, title, number, image, activeTab, children }) => {
    const active = parseInt(activeTab) === parseInt(number);
    return active && (
        <>
            <div id={`tab-${number}`} className={clsx(className, "tabbed-content__tab", `tabbed-content__tab--${layout}`, { 'preserve-image': preserveImage, 'active': active })}>
                <div className="tabbed-content__tab__content">
                    {children}
                </div>

                <div className="tabbed-content__tab__image">
                    {preserveImage ? (
                        <img className="tabbed-content__tab__image__img" src={image} alt={title} />
                    ) : (
                        <div className="tabbed-content__tab__image__background" style={{ backgroundImage: `url(${image})`, backgroundPosition: imagePosition }}></div>
                    )}

                </div>
            </div>
        </>
    );
};

export default Tab;
